var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "work-table-container" }, [
    _c(
      "div",
      { staticClass: "my-orders-wrapper" },
      [
        _c("div", { staticClass: "business-card" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "tip" }, [
              _c("p", [_vm._v("名片信息：")]),
              _c("span", { on: { click: _vm.handleEditJobPersonalInfo } }, [
                _vm._v("编辑"),
              ]),
            ]),
            _c("img", { attrs: { src: _vm.avatarUrl } }),
            _vm.businessCard
              ? _c("div", { staticClass: "main" }, [
                  _c("h3", [
                    _vm._v(
                      "\r\n                        " +
                        _vm._s(_vm.businessCard.realName) +
                        "\r\n                        "
                    ),
                  ]),
                  _c("p", { staticClass: "desc" }, [
                    _vm._v(_vm._s(_vm.businessCard.signature)),
                  ]),
                  _c("p", { staticClass: "contact" }, [
                    _vm.businessCard.mobile
                      ? _c(
                          "span",
                          [
                            _c("font-icon", {
                              staticClass: "personal-info-icon",
                              attrs: { href: "#icon-mobile-full" },
                            }),
                            _vm._v(
                              "\r\n                            " +
                                _vm._s(_vm.businessCard.mobile) +
                                "\r\n                        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.businessCard.wechat
                      ? _c(
                          "span",
                          [
                            _c("font-icon", {
                              staticClass: "personal-info-icon",
                              attrs: { href: "#icon-wechat-full" },
                            }),
                            _vm._v(
                              "\r\n                            " +
                                _vm._s(_vm.businessCard.wechat) +
                                "\r\n                        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.businessCard.email
                      ? _c(
                          "span",
                          [
                            _c("font-icon", {
                              staticClass: "personal-info-icon",
                              attrs: { href: "#icon-email-full" },
                            }),
                            _vm._v(
                              "\r\n                            " +
                                _vm._s(_vm.businessCard.email) +
                                "\r\n                        "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "tip" }, [
              _c("p", [_vm._v("分享中职位：" + _vm._s(_vm.total))]),
            ]),
            _c("span", { staticClass: "count" }, [
              _vm._v("共"),
              _c("i", [_vm._v(_vm._s(_vm.total))]),
              _vm._v("个职位"),
            ]),
          ]),
        ]),
        _c("MyShareTable", {
          ref: "myShareTable",
          attrs: { tableData: _vm.tableData, page: _vm.page },
          on: {
            "page-change": _vm.handlePageChange,
            "cancel-share": _vm.handleCancelShare,
            "edit-job-share-info": _vm.handleEditJobShareInfo,
            "preview-share": _vm.handlePreviewShare,
          },
        }),
        _c("CancelShareDialog", {
          ref: "cancelShareDialog",
          on: { "cancel-share-success": _vm.cancelShareSuccess },
        }),
        _c("JobShareEditDialog", {
          ref: "jobShareEditDialog",
          attrs: { "is-from-share-table": true },
          on: { shareJobCard: _vm.handleRefresh },
        }),
        _c("JobPersonalInfoDialog", {
          ref: "jobPersonalInfoDialog",
          on: { personalInfoEditComfirm: _vm.updatePersonalInfo },
        }),
        _c("ShareJobQrcodeDialog", { ref: "shareJobQrcodeDialog" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }