<template>
    <div class="my-share-table">
        <el-table
            ref="multipleTable"
            border
            height="700"
            style="width:100%"
            tooltip-effect="light"
            :data="tableData"
            v-loading="tableDataLoading"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                width="42"
                type="selection"
                align="center"
                header-align="center"
            ></el-table-column>
            <el-table-column
                width="100"
                label="分享时间"
                header-align="left"
                class-name="name-cell"
                :resizable="false"
            >
                <template slot-scope="scope">
                    <div>
                        {{scope.row.created ? scope.row.created.slice(0,10) : '-'}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                width="240"
                label="职位名称"
                header-align="left"
                class-name="name-cell"
                :resizable="false"
                :show-overflow-tooltip="true"
            >
                <template slot-scope="scope">
                    <div class="name-wrapper">
                        <template v-if="scope.row.activityTags.length > 0">
                            <template v-for="activityTag in scope.row.activityTags">
                                <span v-if="activityTag.id === 'NewJob'" :key="activityTag.id">
                                    <font-icon
                                        class="new-job-tag"
                                        href="#icon-orders_label_new"
                                    ></font-icon>
                                </span>
                            </template>
                        </template>
                        <el-tooltip
                            effect="light"
                            :content="scope.row.jobName"
                            placement="top"
                            :open-delay="popoverDelay"
                            :disabled="tooltipDisabled"
                            @mouseenter.native ="handleShowTooltip($event)"
                        >
                            <span
                                class="job-detail-link"
                                @click.prevent="handleJumpDetail(scope.row, 'job', scope.$index)"
                            >
                                {{scope.row.jobName}}
                            </span>
                        </el-tooltip>
                        <template v-if="scope.row.activityTags.length > 0">
                            <template v-for="activityTag in scope.row.activityTags">
                                <span 
                                    class="name-wrapper-span"
                                    v-if="activityTag.id === 'JobDifficultDegree' || activityTag.id === 'JobEmergency'" 
                                    :key="activityTag.id"
                                >
                                    <font-icon
                                        class="name-wrapper-icon tip-margin"
                                        href="#icon-orders_lable_hard"
                                        v-if="activityTag.id === 'JobDifficultDegree'"
                                    ></font-icon>
                                    <font-icon
                                        class="name-wrapper-icon tip-margin"
                                        href="#icon-orders_lable_urgent"
                                        v-if="activityTag.id === 'JobEmergency'"
                                    ></font-icon>
                                </span>
                            </template>
                        </template>
                        <el-tooltip
                            effect="light"
                            :content="scope.row.expectedRank"
                            placement="top"
                            :open-delay="popoverDelay"
                            v-if="scope.row.expectedRank"
                            :disabled="tooltipDisabled"
                            @mouseenter.native ="handleShowTooltip($event)"
                        >
                            <span class="expected-rank">{{scope.row.expectedRank}}</span>
                        </el-tooltip>
                        <template v-if="scope.row.activityTags.length > 0">
                            <span
                                class="activity-tag"
                                v-for="activityTag in scope.row.activityTags"
                                :key="activityTag.id"
                                v-if="activityTag.id !== 'JobEmergency' && activityTag.id !== 'JobDifficultDegree' && activityTag.id !== 'NewJob' && activityTag.id !== 'StandstillJob'"
                            >
                                {{activityTag.name}}
                            </span>
                        </template>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                width="165"
                label="客户公司"
                prop="customerName"
                header-align="left"
                class-name="name-cell"
                :resizable="false"
                :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
                width="166"
                label="职位状态"
                header-align="left"
                class-name="name-cell"
                :resizable="false"
            >
                <template slot-scope="scope">
                    <div>
                        {{statusMap[scope.row.status] || '分享中'}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                width="138"
                label="对外信息预览"
                header-align="left"
                class-name="name-cell"
                :resizable="false"
            >
                <template slot-scope="scope">
                    <div class="priview-btn" @click="handlePreview(scope.row.id)">预览</div>
                </template>
            </el-table-column>
            <el-table-column
                width="251"
                label="操作"
                header-align="left"
                class-name="name-cell"
                :resizable="false"
            >
                <template slot-scope="scope">
                    <div class="operation-btns">
                        <span class="edit" @click="handleEditJobShareInfo(scope.row)">编辑对外信息</span>
                        <span class="cancel" @click="handleSingleCancelShare(scope.row)">取消分享</span>
                    </div>
                </template>
            </el-table-column>

            <template slot="empty">
                <div>
                    <span class="empty-img"></span>
                    <p class="empty-data">暂无数据</p>
                </div>
            </template>
        </el-table>

        <table-page-tab
            :filterIndex="2"
            :pageTabs="pageTabJson"
        ></table-page-tab>

        <!-- footer -->
        <div class="work-table-footer">
            <div class="footer-left">
                <div class="footer-select">
                    <input hidden v-model="allSelect" type="checkbox">
                    <span class="all-select-checkbox" @click="handleAllSelect"></span>
                    <span>全选</span>
                    <span class="select-count">已选择 <span class="color-orange">{{multipleSelection.length}}</span> 个职位</span>
                </div>
                <el-button
                    class="batch-operation-btn cancel-btn"
                    @click="handleBatchCancelShare"
                    :disabled="multipleSelection.length == 0"
                >
                    取消分享
                </el-button>
            </div>
            <el-pagination
                class="el-pagination-workTable"
                :current-page="page.current"
                :page-sizes="[20, 30, 50]"
                :page-size="page.size"
                layout="total, sizes, prev, pager, next, slot"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
                <span class="pagination-text">
                    <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                    <span @click="handlePagerJump">跳转</span>
                </span>
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import TablePageTab from '#/component/workTable/table-page-tab.vue';
    export default {
        name: "MyShareTable",
        componentName: "MyShareTable",
        components:{
            TablePageTab
        },
        props:{
            page: {
                type: Object,
                default() {
                    return {
                        current: 0,
                        size: 20,
                        total: 0
                    }
                }
            },
            tableData: Array,
        },
        watch: {

        },
        data(){
            return{
                popoverDelay: 300,
                tableDataLoading: false,
                multipleSelection: [],
                pagerJump: 0,
                tooltipDisabled: true,
                pageTabJson: [{
                    enTitle: "Orders",
                    cnTitle: "我的订单",
                    path: "/myOrders/grabbedJob",
                    title: "我抢单的职位",
                    tabIndex: 1,
                    aliveComponent: 'MyOrdersGrabbedJob'
                }, {
                    enTitle: "Orders",
                    cnTitle: "我的订单",
                    path: "/myOrders/createdJob",
                    title: "我创建的职位",
                    tabIndex: 0,
                    aliveComponent: 'MyOrdersCreatedJob'
                }, {
                    enTitle: "Orders",
                    cnTitle: "我的订单",
                    path: "/myOrders/sharedJob",
                    title: "我分享的职位",
                    tabIndex: 2,
                    aliveComponent: 'MyOrdersSharedJob'
                }, {
                    enTitle: "Orders",
                    cnTitle: "我的订单",
                    path: "/myOrders/a2aAuditJob",
                    title: "A2A职位审批",
                    tabIndex: 3,
                    aliveComponent: 'MyOrdersA2aAuditJob'
                }],
                es: {
                    activityTags: [],
                    created: "2020-05-26 16:26:44",
                    customerName: "平安科技集团深圳分部",
                    expectedRank: "c4/T3-a/c2",
                    id: "abc70387-64e3-4f0e-aee2-8d2ca86f8f4b",
                    jobId: "ab200444-50f4-4f88-a7ec-2f8b6b704fe9",
                    jobName: "高级java开发工程师",
                    status: 1
                },
                statusMap: {
                    1: '分享中'
                }
            }
        },
        computed:{
            allSelect(){
                return this.tableData && this.tableData.length > 0 ? this.tableData.length == this.multipleSelection.length : false;
            },
        },
        filters:{
        },
        created(){

        },
        mounted() {

        },
        methods:{
            handleSelectionChange(val){
                this.multipleSelection = val;
            },
            handleAllSelect() {
                this.$refs.multipleTable.toggleAllSelection();
            },
            handleCurrentChange(val){
                this.$emit('page-change',{current:val,type:"current"})
            },
            handleSizeChange(val){
                if(window.localStorage) {
                    localStorage.setItem('LBD_TABLE_PAGE_SIZE',val);
                }
                this.$emit('page-change',{size:val,type:"size"})
            },
            handlePagerJump(){
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.page.total/this.page.size);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.$emit('page-change',{current:currentPager,type:"current"})
                }
            },
            scrollTop(){
                document.querySelector('.el-table__body-wrapper').scrollTop = 0;
            },
            handleJumpDetail(item, type, index) {
                switch(type) {
                    case 'job':
                        // window.open(`/Headhunting/MyCompany.html#/Job/${item.jobId}`, '_blank');
                        window.open(`/#/Job/${item.jobId}`, '_blank');
                        break;
                    case 'customer':
                        // window.open(`/Headhunting/MyCompany.html#/Customer/${item.customerId}`, '_blank');
                        window.open(`/#/Customer/${item.customerId}`, '_blank');
                        break;
                }

                if(type == 'job') {
                    _tracker.track('MyShareOrdersClick', JSON.stringify({
                        jobJd: item.jobId
                    }));
                }
            },
            handlePreview(id) {
                this.$emit('preview-share', id);
            },
            handleEditJobShareInfo(job) {
                this.$emit('edit-job-share-info', job)
            },
            handleSingleCancelShare(job) {
                this.$emit('cancel-share', [job], false);
            },
            handleBatchCancelShare() {
                this.$emit('cancel-share', this.multipleSelection, true);
            },
            handleShowTooltip(event){
                let cell = event.target;
                setTimeout(() => {
                    this.tooltipDisabled = cell.clientWidth == cell.scrollWidth;
                }, 300);
            }
        }
    }

</script>

<style lang="scss" scoped>
.my-share-table /deep/ {
    height: 60.6% !important;
    padding: 0 20px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    letter-spacing: 0px;
    background:url('~@src/assets/images/home/home_bg.png') bottom center no-repeat;
    .el-table {
        overflow-y: auto;
        color: #666;
        z-index: 0;
        .el-table__body-wrapper, .el-table__fixed-body-wrapper {
            padding-bottom: 1px;
        }
        .el-loading-mask {
            z-index: 900;
        }
        .el-table__fixed::before {
            display: none;
        }
        th {
            padding: 0 0 0 13px;
            height: 42px;
            line-height: 42px;
            background-color: #E8E8E8;
            border-color: #CCC;
            color: #666;
            &:nth-of-type(2) {
                padding-left: 2px;
                .el-icon--right {
                    margin-left: 0;
                }
            }
            &.recommend-cell {
                .icon-question {
                    margin-left: 5px;
                }
            }
            &.gutter{
                display: none;
            }
            &.is-center{
                padding: 0;
            }
            .cell {
                padding: 0;
                .el-checkbox {
                    display: none;
                }
            }
            .title-slot{
                padding: 0 !important;
            }
            div{
                display: block;
            }
        }
        td {
            padding: 0;
            height: 40px;
            line-height: 40px;
            .cell {
                white-space: nowrap;
                padding-left: 13px;
                .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #fff;
                    border-color: $primary;
                    &::after {
                        border-color: $primary;
                    }
                }
                &.el-tooltip {
                    div {
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .table-body-icon-mr {
                    margin-right: 4px;
                }
                .expected-rank, .activity-tag {
                    height: 16px;
                    margin-left: 6px;
                    padding: 0 3px;
                    border-radius: 4px;
                    background: $primary;
                    font-size: 12px;
                    line-height: 16px;
                    color: #fff;
                }
                .expected-rank {
                    max-width: 68px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .activity-tag {
                    background: #39f;
                }
                .time-tooltip-show {
                    &:hover {
                        color: $primary;
                    }
                }
                .dispose-wrapper {
                    .dispose-btn {
                        display: none;
                        color: $primary;
                        margin-left: 10px;
                        cursor: pointer;
                    }
                    &:hover {
                        .dispose-btn {
                            display: inline-block;
                        }
                    }
                }
            }
            &.is-center{
                .cell{
                    padding: 0 3px;
                }
            }
            &:nth-of-type(2) .cell{
                padding-left: 2px;
            }
        }
        .el-table__row {
            .el-checkbox__inner {
                border-color: #999;
            }
        }
        .name-cell{
            position: relative;
            .cell {
                display: flex;
                align-items: center;
                .tip-margin{
                    margin-left: 5px;
                    .el-loading-spinner {
                        margin-top: -12px;
                        .circular {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }
            .name-wrapper {
                width: auto;
                max-width: 234px;
                display: inline-flex;
                align-items: center;
                .job-detail-link {
                    min-width: 20px;
                    max-width: 128px;
                }
                .name-wrapper-span {
                    line-height: 17px;
                    vertical-align: bottom;
                }
                .name-wrapper-icon {
                    width: 17px;
                    height: 17px;
                }
            }
            .new-job-tag {
                width: 26px;
                height: 16px;
                position: absolute;
                top: -1px;
                left: -1px;
            }

            .operation-btns {
                font-size: 14px;
                color: $primary;

                span {
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                .edit {
                    margin-right: 8px;
                }

                .cancel {
                    color: #fc7f61;
                }
            }

            .priview-btn {
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .job-detail-link, .customer-detail-link {
            color: #666;
            float: left;
            flex-grow: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
        .customer-detail-link {
            max-width: 136px;
        }
        .el-table__empty-block{
            width: 100%!important;
            min-height: 250px;
            .empty-data{
                display: block;
            }
        }
        .el-table-column--selection {
            border-right: none;
        }
        .el-table__empty-block {
            .el-table__empty-text {
                line-height: inherit;
            }
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                width: 240px;
                height: 228px;
            }
            .empty-data {
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }

    }

}
</style>
<style lang="scss">
.work-table-footer .cancel-btn {
    background-color: #4A90E2;

    &:hover {
        background-color: #3F85D8;
    }
}
.el-tooltip__popper.is-light.table-header-tooltip{
    margin-top: 5px;
    margin-left: 0;
    padding: 12px 20px;
    font-size: 12px;
    color: #666;
    max-width: 380px;
}
.el-tooltip__popper.is-light.time-tooltip-popper {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 2px;
}
.table-header-dropdown-menu {
    .sort-icon {
        margin-left: 5px;
    }
}
</style>
