<template>
    <el-dialog
        width="400px"
        title="分享我的职位"
        custom-class="share-job-qrcode-dialog"
        :visible.sync="dialogVisible"
        v-loading="loading"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <div class="share-qcode-container">
            <img :src="shareQcodeSrc" class="share-qcode-image"/>
            <div class="share-qcode-tip">
                打开微信，扫一扫～快速分享我的职位
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            dialogVisible: false,
            shareId: '' 
        }
    },
    computed: {
        unionId() {
            return this.$store.state.user.userInfo.unionId;
        },
        shareQcodeSrc() {
            return `${_host.portal}/Sharing/QRCode?url=` + encodeURIComponent(`${_host.h5_page}/jobShareDetail.html?shareId=${this.shareId}&unionId=${this.unionId}`);
        }
    },
    methods: {
        show(jobSharingId) {
            this.shareId = jobSharingId;
            this.dialogVisible = true;
        },
        handleCloseDialog() {
            this.dialogVisible = false;
        }
    }
}
</script>

<style lang="scss" scope>
    .share-job-qrcode-dialog.el-dialog {
        .el-dialog__body {
            padding: 15px 0 20px;
        }
        .share-qcode-container {
            height: 126px;
            padding: 12px 36px 0 20px;
            display: flex;
            justify-content: space-between;
            .share-qcode-image {
                width: 114px;
                height: 114px;
            }
            .share-qcode-tip {
                width: 215px;
                margin-top: 8px;
                color: #666;
                line-height: 20px;
            }
        }
    }
</style>
