var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        width: "400px",
        title: "分享我的职位",
        "custom-class": "share-job-qrcode-dialog",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleCloseDialog,
      },
    },
    [
      _c("div", { staticClass: "share-qcode-container" }, [
        _c("img", {
          staticClass: "share-qcode-image",
          attrs: { src: _vm.shareQcodeSrc },
        }),
        _c("div", { staticClass: "share-qcode-tip" }, [
          _vm._v("\n            打开微信，扫一扫～快速分享我的职位\n        "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }