<template>
<div class="work-table-container">
    <div class="my-orders-wrapper">
        <div class="business-card">
            <div class="left">
                <div class="tip">
                    <p>名片信息：</p>
                    <span @click="handleEditJobPersonalInfo">编辑</span>
                </div>
                <img :src="avatarUrl" />
                <div class="main" v-if="businessCard">
                    <h3>
                        {{businessCard.realName}}
                        <!-- <span>可如福克斯</span> -->
                    </h3>
                    <p class="desc">{{businessCard.signature}}</p>
                    <p class="contact">
                        <span v-if="businessCard.mobile">
                            <font-icon class="personal-info-icon" href="#icon-mobile-full"></font-icon>
                            {{businessCard.mobile}}
                        </span>
                        <span v-if="businessCard.wechat">
                            <font-icon class="personal-info-icon" href="#icon-wechat-full"></font-icon>
                            {{businessCard.wechat}}
                        </span>
                        <span v-if="businessCard.email">
                            <font-icon class="personal-info-icon" href="#icon-email-full"></font-icon>
                            {{businessCard.email}}
                        </span>
                    </p>
                </div>
            </div>
            <div class="right">
                <div class="tip">
                    <!-- <p>扫码查看【我的职位名片】：</p> -->
                    <p>分享中职位：{{total}}</p>
                </div>
                <!-- <img :src="cardShareQrcodeUrl" v-if="cardShareQrcodeUrl"/> -->
                <span class="count">共<i>{{total}}</i>个职位</span>
            </div>
        </div>
        <MyShareTable
            ref="myShareTable"
            :tableData="tableData"
            :page="page"
            @page-change="handlePageChange"
            @cancel-share="handleCancelShare"
            @edit-job-share-info="handleEditJobShareInfo"
            @preview-share="handlePreviewShare"
        ></MyShareTable>
        <CancelShareDialog ref="cancelShareDialog" @cancel-share-success="cancelShareSuccess"></CancelShareDialog>
        <JobShareEditDialog ref="jobShareEditDialog" :is-from-share-table="true" @shareJobCard="handleRefresh"></JobShareEditDialog>
        <JobPersonalInfoDialog ref="jobPersonalInfoDialog" @personalInfoEditComfirm="updatePersonalInfo"></JobPersonalInfoDialog>
        <ShareJobQrcodeDialog ref="shareJobQrcodeDialog"></ShareJobQrcodeDialog>
    </div>
</div>
</template>

<script>
    import tableScrollTo from '#/js/mixins/tableScrollTo.js';
    import { avatarHost } from '#/component/page/instant-message/utils/avatarHost.js';
    import MyOrdersService from '#/js/service/myOrdersService.js';
    import MyShareTable from './layout/myShareTable.vue'
    import CancelShareDialog from './component/cancelShareDialog.vue';
    import JobShareEditDialog from '#/component/common/dialog/job-share-edit-dialog.vue';
    import JobPersonalInfoDialog from '#/component/common/dialog/job-personal-info-dialog.vue';
    import ShareJobQrcodeDialog from '#/component/common/dialog/share-job-qrcode-dialog.vue';
    export default {
        name: "MyOrdersSharedJob",
        components: {
            MyShareTable,
            CancelShareDialog,
            JobShareEditDialog,
            JobPersonalInfoDialog,
            ShareJobQrcodeDialog
        },
        data(){
            return{
                businessCard: {},
                tableData: [],
                total: 0,
                page: {
                    current: 1,
                    size: 20,
                    total:0
                },
                pageParams: {
                    start: 0,
                    take: 20,
                },
            }
        },
        computed: {
            userInfo() {
                return this.$store.state.user.userInfo;
            },
            avatarUrl() {
                // return this.businessCard ? `${avatarHost()}/user/${this.businessCard.unionId}` : "@src/assets/images/default-user.png"
                return this.userInfo.avatarUrl;
            },
            cardShareQrcodeUrl() {
                return this.businessCard ? _host.portal + '/Sharing/QRCode?url=' + encodeURIComponent(`${_host.h5_page}/jobShareCard.html?unionId=${this.userInfo.unionId}`) : "";
            },
        },
        mixins: [tableScrollTo],
        activated() {
            this.tableScrollTo();
        },
        mounted() {
            this.getTableData();
        },
        methods: {
            getTableData() {
                this.$refs.myShareTable.tableDataLoading = true;
                MyOrdersService.getJobShareList({
                    start: this.pageParams.start,
                    take: this.pageParams.take
                }).then(res =>{
                    this.businessCard = res.businessCard;
                    this.tableData = res.jobSharingItems;
                    this.total = res.total;
                    this.page.total = res.total;
                }).finally(() => {
                    this.$refs.myShareTable.scrollTop();
                    this.$refs.myShareTable.tableDataLoading = false;
                });
            },
            handlePageChange(page) {
                if (page.type === 'current') {
                    this.page.current = page.current;
                    this.pageParams.start = (page.current - 1) * this.page.size;
                } else {
                    this.page.size = page.size;
                    this.page.current = 1;
                    this.pageParams.take = page.size;
                    this.pageParams.start = 0;
                }
                this.getTableData();
            },
            handleCancelShare(data, isBatch) {
                this.$refs.cancelShareDialog.show(data, isBatch);
            },
            cancelShareSuccess() {
                // this.page.current = 1;
                // this.pageParams.start = 0;
                this.getTableData();
            },
            handleRefresh() {
                this.getTableData();
            },
            handleEditJobShareInfo(job) {
                this.$refs.jobShareEditDialog.show(false, {
                    jobId: job.jobId,
                    jobSharingId: job.id
                });
            },
            handleEditJobPersonalInfo() {
                this.$refs.jobPersonalInfoDialog.show(false);
            },
            updatePersonalInfo(params) {
                if(this.businessCard && this.businessCard.unionId) {
                    this.businessCard = Object.assign({
                        unionId: this.businessCard.unionId
                    }, params);
                }else {
                    this.getTableData();
                }
            },
            handlePreviewShare(jobSharingId) {
                this.$refs.shareJobQrcodeDialog.show(jobSharingId);
            }
        }
    }
</script>

<style lang="scss" scoped>
.work-table-container {
    height: 100%;
    overflow-y: auto;
    .my-orders-wrapper {
        position: relative;
        height: 100%;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .business-card {
            // width:100%;
            margin: 6px 20px;
            padding: 12px;
            background: white;
            border-radius: 4px;

            .left {
                float: left;
                display: flex;
                flex-direction: row;
                width: 688px;
                border-right: 1px solid #E9EAEC;

                .tip {
                    width: 75px;
                    margin-right: 4px;
                    text-align: right;
                    font-size: 14px;
                    color: #666;

                    span {
                        color: $primary;
                        margin-right: 14px;
                        cursor: pointer;
                    }
                }

                > img {
                    display: block;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 12px;
                }

                .main {
                    flex: 1;
                    flex-grow: 1;
                    padding-right: 12px;

                    h3 {
                        font-size: 16px;
                        color: #666;
                        line-height: 22px;
                        margin-bottom: 0;
                        span {
                            margin-left: 6px;
                            font-size: 12px;
                            color: #4E5968;
                        }
                    }

                    p.desc {
                        margin-bottom: 5px;
                        font-size: 12px;
                        color: #666;
                    }
                    p.contact {
                        margin-bottom: 0;
                        font-size: 14px;
                        color: #666;
                        .personal-info-icon {
                            width: 16px;
                            height: 16px;
                            margin-right: 4px;
                        }
                        span + span {
                            margin-left: 8px;
                        }
                    }
                }
            }

            .right {
                overflow: hidden;
                position: relative;
                padding-left: 66px;

                .tip {
                    display: inline-block;
                    font-size: 14px;
                    color: #666666;
                    p {
                        margin-bottom: 0;

                        &:first-child {
                            margin-bottom: 9px;
                        }
                    }
                }

                > img {
                    display: inline-block;
                    width: 73px;
                    height: 73px;
                }

                .count {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    font-size: 14px;
                    color: #666666;

                    i {
                        color: #fd7f00;
                    }
                }
            }
        }
    }
}
</style>
