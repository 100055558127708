<template>
    <el-dialog
        width="480px"
        :title="isBatch ? '批量取消分享职位' : '取消分享职位'"
        :visible.sync="dialogVisible"
        v-loading="loading"
        @close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        确定取消分享职位“{{jobName}}”{{ids.length > 1 ? `等${ids.length}个职位` : ''}}吗？
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCloseDialog">取消</el-button>
            <el-button type="primary" @click="handleSubmit">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import MyOrdersService from '#/js/service/myOrdersService.js';
export default {
    data() {
        return {
            loading: false,
            dialogVisible: false,
            ids: [],
            jobName: '',
            isBatch: false
        }
    },
    methods: {
        show(data, isBatch) {
            this.isBatch = isBatch;
            let ids = [];
            data.forEach((item, index) =>{
                ids.push(item.id);
                if(index == 0) {
                    this.jobName = item.jobName;
                }
            })
            this.ids = ids;
            this.dialogVisible = true;
        },
        handleCloseDialog() {
            this.dialogVisible = false;
            this.visibleFirmIdList = [0];
            this.companyList = [];
        },
        handleSubmit() {
            console.log(this.ids);
            MyOrdersService.batchCancelShare(this.ids)
                .then(res => {
                    shortTips(this.isBatch ? '批量取消分享成功' : '取消分享成功');
                    this.$emit('cancel-share-success');
                }).finally(() =>{
                    this.dialogVisible = false;
                })
        }
    }
}
</script>

<style lang="scss" scope>
    .input-visible-firm.el-select {
        width: 340px;
        .el-tag.el-tag--info {
            font-size: 14px;
            height: 18px;
            line-height: 18px;
            background-color: #fff;
            border: none;
            color: #666;
            padding: 0;
            &:nth-of-type(1) {
                padding-left: 8px;
            }
            &:nth-of-type(n+2) {
                &::before {
                    content: ",";
                    margin-right: 6px;
                }
            }
            .el-tag__close {
                display: none;
            }
        }
    }
</style>