import {myOrders as myOrdersUrl} from '@src/js/config/api.json';
import moment from 'moment';
export default {
    /**
     * 列表搜索方法
     * @returns {*}
     */
    search(params) {
        return _request({
            method: "POST",
            url: myOrdersUrl.my_order_search,
            data: params
        }).then(res => {
            const headerFilter = [
                    {
                        filterName: "jobsNameInfo",
                        labelName: "jobName"
                    },
                    {
                        filterName: "customersInfo",
                        labelName: "customerName"
                    },
                    {
                        filterName: "jobsStatusInfo",
                        labelName: "jobStatus"
                    },
                    {
                        filterName: "tagsInfo",
                        labelName: "name"
                    }
                ],
                statusMap = {
                    1: "招聘中",
                    2: "已暂停",
                    3: "沉睡职位",
                    4: "已完成"
                };
            headerFilter.map(filter => {
                const orderArray = res[filter.filterName];
                if (!orderArray || orderArray.length == 0) {
                    return res[filter.filterName] = [];
                }
                if (filter.filterName === "jobsStatusInfo") {
                    for (let key in statusMap) {
                        let hasJobStatusFilter = orderArray.every(item => {
                            return key != item.jobStatus;
                        });
                        if (hasJobStatusFilter) {
                            orderArray.push({
                                jobStatus: Number(key),
                                count: 0
                            });
                        }
                    }
                    orderArray.sort((item1, item2) => {
                        return (item1.jobStatus - item2.jobStatus);
                    });
                    orderArray.map(item => {
                        item.text = statusMap[item[filter.labelName]];
                        item.label = statusMap[item[filter.labelName]];
                        item.isSelect = false;
                    });
                } else {
                    orderArray.map(item => {
                        item.text = item[filter.labelName];
                        item.label = item[filter.labelName];
                        item.isSelect = false;
                    });
                }
            });
            return res;
        });
    },
    /**
     * 获取气泡数量
     * @param startDate
     * @param endDate
     * @param jobFilter
     */
    getBubbleCount(startDate, endDate, jobFilter) {
        return _request({
            method: "POST",
            url: myOrdersUrl.bubbleCount,
            data: {
                StartDate: startDate,
                EndDate: endDate,
                JobFilter: jobFilter
            }
        })
    },
    /**
     * 获取职位下的所有推荐状态
     * @param jobId 职位ID
     * @param start 开始游标
     * @param take 分页条数
     * @param status 处理进度状态
     * @returns {*}
     */
    getRecommendation(params = {jobId, start, take, status} = {}) {
        return _request({
            method: 'POST',
            url: myOrdersUrl.recommendations,
            data: params,
            throwHttpError: true,
            throwBusinessError: true,
        }).then(res => {
            if(res && res.list && res.list.length > 0){
                res.list.forEach(item => {
                    const date = moment(item.created);
                    item.created = date.format('YYYY-MM-DD HH:mm:ss');
                })
            }
            return res;
        })
    },
    /**
     *批量更新职位状态
     * @param {*} params
     */
    updateJobStatus(jobArr) {
        return _request({
            method: "PUT",
            url: myOrdersUrl.update_job_status,
            data: {
                jobSituationList: jobArr
            }
        });
    },
    batchUpdateJobStatus(params) {
        return _request({
            method: "POST",
            url: myOrdersUrl.batch_update_job_status,
            data: params,
            baseURL: "LbdOpenApi",
        });
    },
    /**
     *
     *职位移交
     * @param {*} jobArr
     * @param {*} userId
     * @returns
     */
    transferJob(jobArr, userId) {
        return _request({
            method: "POST",
            url: myOrdersUrl.transfer_job,
            data: {
                jobIds: jobArr,
                newOwnerId: userId
            }
        });
    },
    /**
     *更新单条的数据
     * @param {*} jobId
     * @param {1为我创建的，2为我抢单的} type
     * @returns
     */
    updateSingle(jobId,type){
        return _request({
            method: "GET",
            url: myOrdersUrl.update_single.replace('%p',jobId),
            data: {
                jobFilter:type
            }
        });
    },
    /**
     *添加标签
     *
     * @param {*} params
     * @returns
     */
    createTag(params){
        return _request({
            method: "POST",
            url: myOrdersUrl.create_tag,
            data: params
        });
    },
    /**
     *删除当前职位的标签
     *
     * @param {*} params
     * @returns
     */
    deleteTag(params){
        return _request({
            method: "DELETE",
            url: myOrdersUrl.delete_tag,
            data:params
        });
    },
    getTags(){
        return _request({
            method: "GET",
            url: myOrdersUrl.get_tags,
        });
    },
    getJobShareList(params = {start, take} = {}) {
        return _request({
            url: myOrdersUrl.get_job_share_list,
            method: 'GET',
            data: params,
            baseURL: "LbdOpenApi",
        })
    },
    batchCancelShare(params = {ids} = {}) {
        return _request({
            url: myOrdersUrl.batch_cancel_share,
            method: 'POST',
            data: params,
            baseURL: "LbdOpenApi",
        })
    }
};
